.index-cont1 {
  background-color: #d6ddd5;
  background-image: url(./images/index_banner1.png);
  background-position: bottom center;
  background-size: 100% auto;
  background-attachment: fixed;
  background-repeat: no-repeat;
  transition: all .6s;
}

.index-cont1.bg2 {
  background-image: url(./images/index_banner2.png);
}

.index-cont1 .index-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.index-cont1 .index-logo .cont {
  text-align: center;
}

.index-cont1 .index-logo .cont::after {
  content: '';
  display: block;
  background-color: #3a7337;
  margin: 0 auto;
  width: 92px;
  height: 5px;
}

@media (max-width: 1600px) {
  .index-cont1 .index-logo .cont::after {
    width: 83.6px;
  }
}

@media (max-width: 1366px) {
  .index-cont1 .index-logo .cont::after {
    width: 75.2px;
  }
}

@media (max-width: 1024px) {
  .index-cont1 .index-logo .cont::after {
    width: 66.8px;
  }
}

@media (max-width: 991px) {
  .index-cont1 .index-logo .cont::after {
    width: 58.4px;
  }
}

@media (max-width: 767px) {
  .index-cont1 .index-logo .cont::after {
    width: 50px;
  }
}

@media (max-width: 1600px) {
  .index-cont1 .index-logo .cont::after {
    height: 4.4px;
  }
}

@media (max-width: 1366px) {
  .index-cont1 .index-logo .cont::after {
    height: 3.8px;
  }
}

@media (max-width: 1024px) {
  .index-cont1 .index-logo .cont::after {
    height: 3.2px;
  }
}

@media (max-width: 991px) {
  .index-cont1 .index-logo .cont::after {
    height: 2.6px;
  }
}

@media (max-width: 767px) {
  .index-cont1 .index-logo .cont::after {
    height: 2px;
  }
}

.index-cont1 .index-logo .cont img {
  height: auto;
  width: 390px;
}

@media (max-width: 991px) {
  .index-cont1 .index-logo .cont img {
    width: 340px;
  }
}

@media (max-width: 767px) {
  .index-cont1 .index-logo .cont img {
    width: 300px;
  }
}

.index-cont1 .index-logo .cont p {
  color: #434a43;
  font-size: 26px;
  margin-top: 30px;
  margin-bottom: 50px;
}

@media (max-width: 1600px) {
  .index-cont1 .index-logo .cont p {
    font-size: 24.4px;
  }
}

@media (max-width: 1366px) {
  .index-cont1 .index-logo .cont p {
    font-size: 22.8px;
  }
}

@media (max-width: 1024px) {
  .index-cont1 .index-logo .cont p {
    font-size: 21.2px;
  }
}

@media (max-width: 991px) {
  .index-cont1 .index-logo .cont p {
    font-size: 19.6px;
  }
}

@media (max-width: 767px) {
  .index-cont1 .index-logo .cont p {
    font-size: 18px;
  }
}

@media (max-width: 1600px) {
  .index-cont1 .index-logo .cont p {
    margin-top: 27px;
  }
}

@media (max-width: 1366px) {
  .index-cont1 .index-logo .cont p {
    margin-top: 24px;
  }
}

@media (max-width: 1024px) {
  .index-cont1 .index-logo .cont p {
    margin-top: 21px;
  }
}

@media (max-width: 991px) {
  .index-cont1 .index-logo .cont p {
    margin-top: 18px;
  }
}

@media (max-width: 767px) {
  .index-cont1 .index-logo .cont p {
    margin-top: 15px;
  }
}

@media (max-width: 1600px) {
  .index-cont1 .index-logo .cont p {
    margin-bottom: 45px;
  }
}

@media (max-width: 1366px) {
  .index-cont1 .index-logo .cont p {
    margin-bottom: 40px;
  }
}

@media (max-width: 1024px) {
  .index-cont1 .index-logo .cont p {
    margin-bottom: 35px;
  }
}

@media (max-width: 991px) {
  .index-cont1 .index-logo .cont p {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .index-cont1 .index-logo .cont p {
    margin-bottom: 25px;
  }
}

.index-cont1 .index-list {
  align-items: center;
  justify-content: center;
  display: flex;
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 50px;
  padding-bottom: 200px;
}

@media (max-width: 1600px) {
  .index-cont1 .index-list {
    padding-left: 98px;
  }
}

@media (max-width: 1366px) {
  .index-cont1 .index-list {
    padding-left: 76px;
  }
}

@media (max-width: 1024px) {
  .index-cont1 .index-list {
    padding-left: 54px;
  }
}

@media (max-width: 991px) {
  .index-cont1 .index-list {
    padding-left: 32px;
  }
}

@media (max-width: 767px) {
  .index-cont1 .index-list {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-cont1 .index-list {
    padding-right: 98px;
  }
}

@media (max-width: 1366px) {
  .index-cont1 .index-list {
    padding-right: 76px;
  }
}

@media (max-width: 1024px) {
  .index-cont1 .index-list {
    padding-right: 54px;
  }
}

@media (max-width: 991px) {
  .index-cont1 .index-list {
    padding-right: 32px;
  }
}

@media (max-width: 767px) {
  .index-cont1 .index-list {
    padding-right: 10px;
  }
}

@media (max-width: 1600px) {
  .index-cont1 .index-list {
    padding-top: 42px;
  }
}

@media (max-width: 1366px) {
  .index-cont1 .index-list {
    padding-top: 34px;
  }
}

@media (max-width: 1024px) {
  .index-cont1 .index-list {
    padding-top: 26px;
  }
}

@media (max-width: 991px) {
  .index-cont1 .index-list {
    padding-top: 18px;
  }
}

@media (max-width: 767px) {
  .index-cont1 .index-list {
    padding-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-cont1 .index-list {
    padding-bottom: 166px;
  }
}

@media (max-width: 1366px) {
  .index-cont1 .index-list {
    padding-bottom: 132px;
  }
}

@media (max-width: 1024px) {
  .index-cont1 .index-list {
    padding-bottom: 98px;
  }
}

@media (max-width: 991px) {
  .index-cont1 .index-list {
    padding-bottom: 64px;
  }
}

@media (max-width: 767px) {
  .index-cont1 .index-list {
    padding-bottom: 30px;
  }
}

.index-cont1 .index-list ul {
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
}

.index-cont1 .index-list ul li {
  background-color: #fff;
  transition: all .3s;
  width: 22%;
  margin-bottom: 0;
}

@media (max-width: 1024px) {
  .index-cont1 .index-list ul li {
    width: 24%;
  }
}

@media (max-width: 991px) {
  .index-cont1 .index-list ul li {
    width: 48%;
  }
}

@media (max-width: 991px) {
  .index-cont1 .index-list ul li {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .index-cont1 .index-list ul li {
    margin-bottom: 10px;
  }
}

.index-cont1 .index-list ul li:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  background-color: #3a7337;
}

.index-cont1 .index-list ul li:hover .item-img img {
  transform: scale(1.05);
}

.index-cont1 .index-list ul li:hover .item-text {
  color: #fff;
}

.index-cont1 .index-list ul li:hover .item-text .item-title::after {
  background-color: #91b122;
}

.index-cont1 .index-list ul li .item-img {
  overflow: hidden;
}

.index-cont1 .index-list ul li .item-img img {
  display: block;
  width: 100%;
  height: auto;
  transition: all .3s;
}

.index-cont1 .index-list ul li .item-text {
  transition: all .3s;
  padding-right: 40px;
  padding-left: 55px;
  padding-top: 40px;
  padding-bottom: 45px;
}

@media (max-width: 1600px) {
  .index-cont1 .index-list ul li .item-text {
    padding-right: 35px;
  }
}

@media (max-width: 1366px) {
  .index-cont1 .index-list ul li .item-text {
    padding-right: 30px;
  }
}

@media (max-width: 1024px) {
  .index-cont1 .index-list ul li .item-text {
    padding-right: 25px;
  }
}

@media (max-width: 991px) {
  .index-cont1 .index-list ul li .item-text {
    padding-right: 20px;
  }
}

@media (max-width: 767px) {
  .index-cont1 .index-list ul li .item-text {
    padding-right: 15px;
  }
}

@media (max-width: 1600px) {
  .index-cont1 .index-list ul li .item-text {
    padding-left: 47px;
  }
}

@media (max-width: 1366px) {
  .index-cont1 .index-list ul li .item-text {
    padding-left: 39px;
  }
}

@media (max-width: 1024px) {
  .index-cont1 .index-list ul li .item-text {
    padding-left: 31px;
  }
}

@media (max-width: 991px) {
  .index-cont1 .index-list ul li .item-text {
    padding-left: 23px;
  }
}

@media (max-width: 767px) {
  .index-cont1 .index-list ul li .item-text {
    padding-left: 15px;
  }
}

@media (max-width: 1600px) {
  .index-cont1 .index-list ul li .item-text {
    padding-top: 36px;
  }
}

@media (max-width: 1366px) {
  .index-cont1 .index-list ul li .item-text {
    padding-top: 32px;
  }
}

@media (max-width: 1024px) {
  .index-cont1 .index-list ul li .item-text {
    padding-top: 28px;
  }
}

@media (max-width: 991px) {
  .index-cont1 .index-list ul li .item-text {
    padding-top: 24px;
  }
}

@media (max-width: 767px) {
  .index-cont1 .index-list ul li .item-text {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index-cont1 .index-list ul li .item-text {
    padding-bottom: 40px;
  }
}

@media (max-width: 1366px) {
  .index-cont1 .index-list ul li .item-text {
    padding-bottom: 35px;
  }
}

@media (max-width: 1024px) {
  .index-cont1 .index-list ul li .item-text {
    padding-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .index-cont1 .index-list ul li .item-text {
    padding-bottom: 25px;
  }
}

@media (max-width: 767px) {
  .index-cont1 .index-list ul li .item-text {
    padding-bottom: 20px;
  }
}

.index-cont1 .index-list ul li .item-text .item-title {
  font-size: 22px;
}

@media (max-width: 1600px) {
  .index-cont1 .index-list ul li .item-text .item-title {
    font-size: 21.2px;
  }
}

@media (max-width: 1366px) {
  .index-cont1 .index-list ul li .item-text .item-title {
    font-size: 20.4px;
  }
}

@media (max-width: 1024px) {
  .index-cont1 .index-list ul li .item-text .item-title {
    font-size: 19.6px;
  }
}

@media (max-width: 991px) {
  .index-cont1 .index-list ul li .item-text .item-title {
    font-size: 18.8px;
  }
}

@media (max-width: 767px) {
  .index-cont1 .index-list ul li .item-text .item-title {
    font-size: 18px;
  }
}

.index-cont1 .index-list ul li .item-text .item-title::after {
  content: '';
  display: block;
  background-color: #3a7337;
  width: 43px;
  height: 3px;
  margin-top: 25px;
  margin-bottom: 40px;
}

@media (max-width: 1600px) {
  .index-cont1 .index-list ul li .item-text .item-title::after {
    width: 40.4px;
  }
}

@media (max-width: 1366px) {
  .index-cont1 .index-list ul li .item-text .item-title::after {
    width: 37.8px;
  }
}

@media (max-width: 1024px) {
  .index-cont1 .index-list ul li .item-text .item-title::after {
    width: 35.2px;
  }
}

@media (max-width: 991px) {
  .index-cont1 .index-list ul li .item-text .item-title::after {
    width: 32.6px;
  }
}

@media (max-width: 767px) {
  .index-cont1 .index-list ul li .item-text .item-title::after {
    width: 30px;
  }
}

@media (max-width: 1600px) {
  .index-cont1 .index-list ul li .item-text .item-title::after {
    height: 2.8px;
  }
}

@media (max-width: 1366px) {
  .index-cont1 .index-list ul li .item-text .item-title::after {
    height: 2.6px;
  }
}

@media (max-width: 1024px) {
  .index-cont1 .index-list ul li .item-text .item-title::after {
    height: 2.4px;
  }
}

@media (max-width: 991px) {
  .index-cont1 .index-list ul li .item-text .item-title::after {
    height: 2.2px;
  }
}

@media (max-width: 767px) {
  .index-cont1 .index-list ul li .item-text .item-title::after {
    height: 2px;
  }
}

@media (max-width: 1600px) {
  .index-cont1 .index-list ul li .item-text .item-title::after {
    margin-top: 23px;
  }
}

@media (max-width: 1366px) {
  .index-cont1 .index-list ul li .item-text .item-title::after {
    margin-top: 21px;
  }
}

@media (max-width: 1024px) {
  .index-cont1 .index-list ul li .item-text .item-title::after {
    margin-top: 19px;
  }
}

@media (max-width: 991px) {
  .index-cont1 .index-list ul li .item-text .item-title::after {
    margin-top: 17px;
  }
}

@media (max-width: 767px) {
  .index-cont1 .index-list ul li .item-text .item-title::after {
    margin-top: 15px;
  }
}

@media (max-width: 1600px) {
  .index-cont1 .index-list ul li .item-text .item-title::after {
    margin-bottom: 36px;
  }
}

@media (max-width: 1366px) {
  .index-cont1 .index-list ul li .item-text .item-title::after {
    margin-bottom: 32px;
  }
}

@media (max-width: 1024px) {
  .index-cont1 .index-list ul li .item-text .item-title::after {
    margin-bottom: 28px;
  }
}

@media (max-width: 991px) {
  .index-cont1 .index-list ul li .item-text .item-title::after {
    margin-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .index-cont1 .index-list ul li .item-text .item-title::after {
    margin-bottom: 20px;
  }
}

.index-cont1 .index-list ul li .item-text .item-desc {
  line-height: 1.87;
}

@media (max-width: 1600px) {
  .index-cont1 .index-list ul li .item-text .item-desc {
    line-height: 1.816;
  }
}

@media (max-width: 1366px) {
  .index-cont1 .index-list ul li .item-text .item-desc {
    line-height: 1.762;
  }
}

@media (max-width: 1024px) {
  .index-cont1 .index-list ul li .item-text .item-desc {
    line-height: 1.708;
  }
}

@media (max-width: 991px) {
  .index-cont1 .index-list ul li .item-text .item-desc {
    line-height: 1.654;
  }
}

@media (max-width: 767px) {
  .index-cont1 .index-list ul li .item-text .item-desc {
    line-height: 1.6;
  }
}

.index-news ul li:nth-child(even) a {
  flex-direction: row-reverse;
}

.index-news ul li a {
  align-items: stretch;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 767px) {
  .index-news ul li a {
    display: block;
  }
}

.index-news ul li a .item-img {
  flex-shrink: 0;
  position: relative;
  width: 50%;
}

@media (max-width: 767px) {
  .index-news ul li a .item-img {
    width: 100%;
  }
}

.index-news ul li a .item-img::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.index-news ul li a .item-img img {
  display: block;
  width: 100%;
  height: auto;
  opacity: 0;
}

.index-news ul li a .item-img b {
  display: block;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.index-news ul li a .item-img .text {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
  box-sizing: border-box;
  color: #fff;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  line-height: 1.8;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 115px;
  padding-bottom: 55px;
  font-size: 20px;
}

@media (max-width: 1600px) {
  .index-news ul li a .item-img .text {
    line-height: 1.76;
  }
}

@media (max-width: 1366px) {
  .index-news ul li a .item-img .text {
    line-height: 1.72;
  }
}

@media (max-width: 1024px) {
  .index-news ul li a .item-img .text {
    line-height: 1.68;
  }
}

@media (max-width: 991px) {
  .index-news ul li a .item-img .text {
    line-height: 1.64;
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-img .text {
    line-height: 1.6;
  }
}

@media (max-width: 1600px) {
  .index-news ul li a .item-img .text {
    padding-left: 83px;
  }
}

@media (max-width: 1366px) {
  .index-news ul li a .item-img .text {
    padding-left: 66px;
  }
}

@media (max-width: 1024px) {
  .index-news ul li a .item-img .text {
    padding-left: 49px;
  }
}

@media (max-width: 991px) {
  .index-news ul li a .item-img .text {
    padding-left: 32px;
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-img .text {
    padding-left: 15px;
  }
}

@media (max-width: 1600px) {
  .index-news ul li a .item-img .text {
    padding-right: 83px;
  }
}

@media (max-width: 1366px) {
  .index-news ul li a .item-img .text {
    padding-right: 66px;
  }
}

@media (max-width: 1024px) {
  .index-news ul li a .item-img .text {
    padding-right: 49px;
  }
}

@media (max-width: 991px) {
  .index-news ul li a .item-img .text {
    padding-right: 32px;
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-img .text {
    padding-right: 15px;
  }
}

@media (max-width: 1600px) {
  .index-news ul li a .item-img .text {
    padding-top: 95px;
  }
}

@media (max-width: 1366px) {
  .index-news ul li a .item-img .text {
    padding-top: 75px;
  }
}

@media (max-width: 1024px) {
  .index-news ul li a .item-img .text {
    padding-top: 55px;
  }
}

@media (max-width: 991px) {
  .index-news ul li a .item-img .text {
    padding-top: 35px;
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-img .text {
    padding-top: 15px;
  }
}

@media (max-width: 1600px) {
  .index-news ul li a .item-img .text {
    padding-bottom: 47px;
  }
}

@media (max-width: 1366px) {
  .index-news ul li a .item-img .text {
    padding-bottom: 39px;
  }
}

@media (max-width: 1024px) {
  .index-news ul li a .item-img .text {
    padding-bottom: 31px;
  }
}

@media (max-width: 991px) {
  .index-news ul li a .item-img .text {
    padding-bottom: 23px;
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-img .text {
    padding-bottom: 15px;
  }
}

@media (max-width: 1600px) {
  .index-news ul li a .item-img .text {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .index-news ul li a .item-img .text {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .index-news ul li a .item-img .text {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .index-news ul li a .item-img .text {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-img .text {
    font-size: 16px;
  }
}

.index-news ul li a .item-text {
  flex-shrink: 0;
  box-sizing: border-box;
  width: 50%;
  padding-left: 150px;
  padding-right: 150px;
  padding-top: 110px;
  padding-bottom: 130px;
}

@media (max-width: 767px) {
  .index-news ul li a .item-text {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .index-news ul li a .item-text {
    padding-left: 123px;
  }
}

@media (max-width: 1366px) {
  .index-news ul li a .item-text {
    padding-left: 96px;
  }
}

@media (max-width: 1024px) {
  .index-news ul li a .item-text {
    padding-left: 69px;
  }
}

@media (max-width: 991px) {
  .index-news ul li a .item-text {
    padding-left: 42px;
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-text {
    padding-left: 15px;
  }
}

@media (max-width: 1600px) {
  .index-news ul li a .item-text {
    padding-right: 123px;
  }
}

@media (max-width: 1366px) {
  .index-news ul li a .item-text {
    padding-right: 96px;
  }
}

@media (max-width: 1024px) {
  .index-news ul li a .item-text {
    padding-right: 69px;
  }
}

@media (max-width: 991px) {
  .index-news ul li a .item-text {
    padding-right: 42px;
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-text {
    padding-right: 15px;
  }
}

@media (max-width: 1600px) {
  .index-news ul li a .item-text {
    padding-top: 91px;
  }
}

@media (max-width: 1366px) {
  .index-news ul li a .item-text {
    padding-top: 72px;
  }
}

@media (max-width: 1024px) {
  .index-news ul li a .item-text {
    padding-top: 53px;
  }
}

@media (max-width: 991px) {
  .index-news ul li a .item-text {
    padding-top: 34px;
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-text {
    padding-top: 15px;
  }
}

@media (max-width: 1600px) {
  .index-news ul li a .item-text {
    padding-bottom: 107px;
  }
}

@media (max-width: 1366px) {
  .index-news ul li a .item-text {
    padding-bottom: 84px;
  }
}

@media (max-width: 1024px) {
  .index-news ul li a .item-text {
    padding-bottom: 61px;
  }
}

@media (max-width: 991px) {
  .index-news ul li a .item-text {
    padding-bottom: 38px;
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-text {
    padding-bottom: 15px;
  }
}

.index-news ul li a .item-text .cont {
  height: 100%;
  box-sizing: border-box;
  position: relative;
  padding-bottom: 100px;
}

@media (max-width: 1600px) {
  .index-news ul li a .item-text .cont {
    padding-bottom: 92px;
  }
}

@media (max-width: 1366px) {
  .index-news ul li a .item-text .cont {
    padding-bottom: 84px;
  }
}

@media (max-width: 1024px) {
  .index-news ul li a .item-text .cont {
    padding-bottom: 76px;
  }
}

@media (max-width: 991px) {
  .index-news ul li a .item-text .cont {
    padding-bottom: 68px;
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-text .cont {
    padding-bottom: 60px;
  }
}

.index-news ul li a .item-text .cont .item-tag {
  position: relative;
}

.index-news ul li a .item-text .cont .item-tag::after {
  content: '';
  display: block;
  border-radius: 50%;
  background-color: #91b122;
  position: absolute;
  top: calc(50% - 4px);
  left: -38px;
  width: 8px;
  height: 8px;
}

@media (max-width: 1024px) {
  .index-news ul li a .item-text .cont .item-tag::after {
    top: calc(50% - 3px);
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-text .cont .item-tag::after {
    top: calc(50% - 2px);
  }
}

@media (max-width: 1024px) {
  .index-news ul li a .item-text .cont .item-tag::after {
    left: -25px;
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-text .cont .item-tag::after {
    left: -10px;
  }
}

@media (max-width: 1024px) {
  .index-news ul li a .item-text .cont .item-tag::after {
    width: 6px;
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-text .cont .item-tag::after {
    width: 4px;
  }
}

@media (max-width: 1024px) {
  .index-news ul li a .item-text .cont .item-tag::after {
    height: 6px;
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-text .cont .item-tag::after {
    height: 4px;
  }
}

.index-news ul li a .item-text .cont .item-title {
  color: #3a7337;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 1.6;
  font-size: 32px;
  margin-top: 65px;
  margin-bottom: 50px;
}

@media (max-width: 1600px) {
  .index-news ul li a .item-text .cont .item-title {
    font-size: 29.2px;
  }
}

@media (max-width: 1366px) {
  .index-news ul li a .item-text .cont .item-title {
    font-size: 26.4px;
  }
}

@media (max-width: 1024px) {
  .index-news ul li a .item-text .cont .item-title {
    font-size: 23.6px;
  }
}

@media (max-width: 991px) {
  .index-news ul li a .item-text .cont .item-title {
    font-size: 20.8px;
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-text .cont .item-title {
    font-size: 18px;
  }
}

@media (max-width: 1600px) {
  .index-news ul li a .item-text .cont .item-title {
    margin-top: 55px;
  }
}

@media (max-width: 1366px) {
  .index-news ul li a .item-text .cont .item-title {
    margin-top: 45px;
  }
}

@media (max-width: 1024px) {
  .index-news ul li a .item-text .cont .item-title {
    margin-top: 35px;
  }
}

@media (max-width: 991px) {
  .index-news ul li a .item-text .cont .item-title {
    margin-top: 25px;
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-text .cont .item-title {
    margin-top: 15px;
  }
}

@media (max-width: 1600px) {
  .index-news ul li a .item-text .cont .item-title {
    margin-bottom: 42px;
  }
}

@media (max-width: 1366px) {
  .index-news ul li a .item-text .cont .item-title {
    margin-bottom: 34px;
  }
}

@media (max-width: 1024px) {
  .index-news ul li a .item-text .cont .item-title {
    margin-bottom: 26px;
  }
}

@media (max-width: 991px) {
  .index-news ul li a .item-text .cont .item-title {
    margin-bottom: 18px;
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-text .cont .item-title {
    margin-bottom: 10px;
  }
}

.index-news ul li a .item-text .cont .item-desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  overflow: hidden;
  line-height: 2.25;
}

@media (max-width: 1600px) {
  .index-news ul li a .item-text .cont .item-desc {
    line-height: 2.12;
  }
}

@media (max-width: 1366px) {
  .index-news ul li a .item-text .cont .item-desc {
    line-height: 1.99;
  }
}

@media (max-width: 1024px) {
  .index-news ul li a .item-text .cont .item-desc {
    line-height: 1.86;
  }
}

@media (max-width: 991px) {
  .index-news ul li a .item-text .cont .item-desc {
    line-height: 1.73;
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-text .cont .item-desc {
    line-height: 1.6;
  }
}

.index-news ul li a .item-text .cont .item-link {
  position: absolute;
  left: 0;
  bottom: 0;
  border: 1px solid #3a7337;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3a7337;
  transition: all .3s;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 13px;
  padding-bottom: 13px;
}

@media (max-width: 1600px) {
  .index-news ul li a .item-text .cont .item-link {
    padding-left: 36px;
  }
}

@media (max-width: 1366px) {
  .index-news ul li a .item-text .cont .item-link {
    padding-left: 32px;
  }
}

@media (max-width: 1024px) {
  .index-news ul li a .item-text .cont .item-link {
    padding-left: 28px;
  }
}

@media (max-width: 991px) {
  .index-news ul li a .item-text .cont .item-link {
    padding-left: 24px;
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-text .cont .item-link {
    padding-left: 20px;
  }
}

@media (max-width: 1600px) {
  .index-news ul li a .item-text .cont .item-link {
    padding-right: 36px;
  }
}

@media (max-width: 1366px) {
  .index-news ul li a .item-text .cont .item-link {
    padding-right: 32px;
  }
}

@media (max-width: 1024px) {
  .index-news ul li a .item-text .cont .item-link {
    padding-right: 28px;
  }
}

@media (max-width: 991px) {
  .index-news ul li a .item-text .cont .item-link {
    padding-right: 24px;
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-text .cont .item-link {
    padding-right: 20px;
  }
}

@media (max-width: 1600px) {
  .index-news ul li a .item-text .cont .item-link {
    padding-top: 12.4px;
  }
}

@media (max-width: 1366px) {
  .index-news ul li a .item-text .cont .item-link {
    padding-top: 11.8px;
  }
}

@media (max-width: 1024px) {
  .index-news ul li a .item-text .cont .item-link {
    padding-top: 11.2px;
  }
}

@media (max-width: 991px) {
  .index-news ul li a .item-text .cont .item-link {
    padding-top: 10.6px;
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-text .cont .item-link {
    padding-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-news ul li a .item-text .cont .item-link {
    padding-bottom: 12.4px;
  }
}

@media (max-width: 1366px) {
  .index-news ul li a .item-text .cont .item-link {
    padding-bottom: 11.8px;
  }
}

@media (max-width: 1024px) {
  .index-news ul li a .item-text .cont .item-link {
    padding-bottom: 11.2px;
  }
}

@media (max-width: 991px) {
  .index-news ul li a .item-text .cont .item-link {
    padding-bottom: 10.6px;
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-text .cont .item-link {
    padding-bottom: 10px;
  }
}

.index-news ul li a .item-text .cont .item-link .iconfont {
  transition: transform .3s;
  margin-left: 10px;
  font-size: 24px;
}

@media (max-width: 1600px) {
  .index-news ul li a .item-text .cont .item-link .iconfont {
    margin-left: 9px;
  }
}

@media (max-width: 1366px) {
  .index-news ul li a .item-text .cont .item-link .iconfont {
    margin-left: 8px;
  }
}

@media (max-width: 1024px) {
  .index-news ul li a .item-text .cont .item-link .iconfont {
    margin-left: 7px;
  }
}

@media (max-width: 991px) {
  .index-news ul li a .item-text .cont .item-link .iconfont {
    margin-left: 6px;
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-text .cont .item-link .iconfont {
    margin-left: 5px;
  }
}

@media (max-width: 1600px) {
  .index-news ul li a .item-text .cont .item-link .iconfont {
    font-size: 22.8px;
  }
}

@media (max-width: 1366px) {
  .index-news ul li a .item-text .cont .item-link .iconfont {
    font-size: 21.6px;
  }
}

@media (max-width: 1024px) {
  .index-news ul li a .item-text .cont .item-link .iconfont {
    font-size: 20.4px;
  }
}

@media (max-width: 991px) {
  .index-news ul li a .item-text .cont .item-link .iconfont {
    font-size: 19.2px;
  }
}

@media (max-width: 767px) {
  .index-news ul li a .item-text .cont .item-link .iconfont {
    font-size: 18px;
  }
}

.index-news ul li a .item-text .cont .item-link:hover {
  background-color: #3a7337;
  color: #fff;
}

.index-news ul li a .item-text .cont .item-link:hover .iconfont {
  transform: translateX(5px);
}
