@import "../../assets/styles/utils";

.index-cont1{
    background-color: $color-main-light;
    background-image: url(./images/index_banner1.png);
    background-position: bottom center;
    background-size: 100% auto;
    background-attachment: fixed;
    background-repeat: no-repeat;
    transition: all .6s;
    &.bg2{
        background-image: url(./images/index_banner2.png);
    }
    .index-logo{
        display: flex;
        align-items: center;
        justify-content: center;
        @include res(height,100vh);
        .cont{
            text-align: center;
            &::after{
                content: '';
                display: block;
                background-color: $color-main;
                margin: 0 auto;
                @include res(width,92px, 50 / 92);
                @include res(height,5px, 2 / 5);
            }
            img{
                height: auto;
                @include res(width,390px,(sm:340px,xs:300px));
            }
            p{
                color: #434a43;
                @include res(font-size,26px, 18 / 26);
                @include res(margin-top,30px, 15 / 30);
                @include res(margin-bottom,50px, 25 / 50);
            }
        }
    }
    .index-list{
        align-items: center;
        justify-content: center;
        @include res(display,flex);
        @include res(padding-left,120px, 10 / 120);
        @include res(padding-right,120px, 10 / 120);
        @include res(padding-top,50px, 10 / 50);
        @include res(padding-bottom,200px, 30 / 200);
        ul{
            align-items: stretch;
            justify-content: space-between;
            flex-wrap: wrap;
            @include res(display,flex);
            li{
                background-color: #fff;
                transition: all .3s;
                @include res(width,22%,(md:24%,sm:48%));
                @include res(margin-bottom,0,(sm:30px,xs:10px));
                &:hover{
                    box-shadow: 0 10px 20px rgba(0,0,0,.3);
                    background-color: $color-main;
                    .item-img img{
                        transform: scale(1.05);
                    }
                    .item-text{
                        color: #fff;
                        .item-title::after{
                            background-color: #91b122;
                        }
                    }
                }
                .item-img{
                    overflow: hidden;
                    img{
                        display: block;
                        width: 100%;
                        height: auto;
                        transition: all .3s;
                    }
                }
                .item-text{
                    transition: all .3s;
                    @include res(padding-right,40px, 15 / 40);
                    @include res(padding-left,55px, 15 / 55);
                    @include res(padding-top,40px, 20 / 40);
                    @include res(padding-bottom,45px, 20 / 45);
                    .item-title{
                        @include res(font-size,22px, 18 / 22);
                        &::after{
                            content: '';
                            display: block;
                            background-color: $color-main;
                            @include res(width,43px, 30 / 43);
                            @include res(height,3px, 2 / 3);
                            @include res(margin-top,25px, 15 / 25);
                            @include res(margin-bottom,40px, 20 / 40);
                        }
                    }
                    .item-desc{
                        @include res(line-height,1.87, 1.6 / 1.87);
                    }
                }
            }
        }
    }
}

.index-news{
    ul{
        li{
            &:nth-child(even){
                a{
                    flex-direction: row-reverse;
                }
            }
            a{
                align-items: stretch;
                justify-content: space-between;
                @include res(display,flex,(xs:block));
                .item-img{
                    flex-shrink: 0;
                    position: relative;
                    @include res(width,50%,(xs:100%));
                    &::after{
                        content: '';
                        display: block;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0,0,0,.3);
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 2;
                    }
                    img{
                        display: block;
                        width: 100%;
                        height: auto;
                        opacity: 0;
                    }
                    b{
                        display: block;
                        width: 100%;
                        height: 100%;
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;
                    }
                    .text{
                        width: 100%;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        z-index: 3;
                        box-sizing: border-box;
                        color: #fff;
                        background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.6));
                        @include res(line-height,1.8, 1.6 / 1.8);
                        @include res(padding-left,100px, 15 / 100);
                        @include res(padding-right,100px, 15 / 100);
                        @include res(padding-top,115px, 15 / 115);
                        @include res(padding-bottom,55px, 15 / 55);
                        @include res(font-size,20px, 16 / 20);
                    }
                }
                .item-text{
                    flex-shrink: 0;
                    box-sizing: border-box;
                    @include res(width,50%,(xs:100%));
                    @include res(padding-left,150px, 15 / 150);
                    @include res(padding-right,150px, 15 / 150);
                    @include res(padding-top,110px, 15 / 110);
                    @include res(padding-bottom,130px, 15 / 130);
                    .cont{
                        height: 100%;
                        box-sizing: border-box;
                        position: relative;
                        @include res(padding-bottom,100px, 60 / 100);
                        .item-tag{
                            position: relative;
                            &::after{
                                content: '';
                                display: block;
                                border-radius: 50%;
                                background-color: #91b122;
                                position: absolute;
                                @include res(top,calc(50% - 4px),(md:calc(50% - 3px),xs:calc(50% - 2px)));
                                @include res(left,-38px,(md:-25px,xs:-10px));
                                @include res(width,8px,(md:6px,xs:4px));
                                @include res(height,8px,(md:6px,xs:4px));
                            }
                        }
                        .item-title{
                            color: $color-main;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            line-height: 1.6;
                            @include res(font-size,32px,18 / 32);
                            @include res(margin-top,65px, 15 / 65);
                            @include res(margin-bottom,50px, 10 / 50);
                        }
                        .item-desc{
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 8;
                            overflow: hidden;
                            @include res(line-height,2.25, 1.6 / 2.25);
                        }
                        .item-link{
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            border: 1px solid $color-main;
                            border-radius: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: $color-main;
                            transition: all .3s;
                            @include res(padding-left,40px, 20 / 40);
                            @include res(padding-right,40px, 20 / 40);
                            @include res(padding-top,13px, 10 / 13);
                            @include res(padding-bottom,13px, 10 / 13);
                            .iconfont{
                                transition: transform .3s;
                                @include res(margin-left,10px, 5 / 10);
                                @include res(font-size,24px, 18 / 24);
                            }
                            &:hover{
                                background-color: $color-main;
                                color: #fff;
                                .iconfont{
                                    transform: translateX(5px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}